.heading{font-size: 26px; }
.blogTaile{display: grid;grid-template-columns: repeat(3, 1fr);grid-gap: 24px;}
.blogLeft{max-width: 374px;background-color: #f8f8f8;border-radius: 10px;overflow: hidden;}
.blogLeft figure{border-radius: 10px;overflow: hidden;display: flex;max-height: 240px;height: 100%; cursor:pointer;}
.blogLeft .blogLeftBody{padding: 15px;cursor: pointer;}
.blogLeft .blogLeftBody .blogTag{background: #eee;border-radius: 5px;padding: 3px 10px;}
.blogCenter{display: grid;grid-template-columns: repeat(2, 1fr);grid-gap: 24px;}
.blogMidTile{position: relative;display: flex;width: 100%;border-radius: 10px;overflow: hidden;}
.blogMidTile img{height: 100%;width: 100%;object-fit: cover;}
.blogMidTile.midFix{height: 175px;}
.blogMidTile .imgBx{width: 100%;height: 100%;display: grid;position: relative;max-height: 188px;cursor: pointer;}
.blogMidTile .imgBx:after {content: '';border-radius: 10px;background-image: linear-gradient(0deg, rgba(0,0,0,67%) 0%, rgba(0,0,0,0.05) 100%);position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 1;}
.midBlogtext{position: absolute;z-index: 2;width: 100%; bottom: 0;padding:0px 16px 10px;display: flex;flex-direction: column;align-items: flex-start;grid-gap: 10px;font-weight: 500;}
.midBlogtext span{display: inline-block;padding: 1px 10px;border-radius: 5px;font-size: 10px;}
.midBlogtext p{white-space: initial;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;font-size: 12px; line-height: 16px;}
.blogSmList{display: flex;grid-gap: 16px;border-bottom: solid 1px #cbcbcb;padding-bottom: 16px;margin-bottom: 16px;}
.blogSmList figure{border-radius: 5px;overflow: hidden;flex-shrink: 0;display: flex;width: 100px;height: 100px;cursor: pointer;}
.blogSmList figure img{object-fit: cover;}
.blogSmList .tag{font-size: 10px;padding: 5px 7px;border-radius: 5px;}
.blogSmList:last-child{margin: 0;padding: 0;border: none;}
.blogRightList{max-height: 379px; overflow: auto;}
.rightBlogDetails{display: flex;justify-content: space-between;flex-direction: column;align-items: flex-start;}

@media screen and (max-width: 768px){
	.blogLeft figure{max-height: inherit;height: inherit;}
	.blogLeft figure img{object-fit: contain;height: 100%;width: 100%;}
	.blogLeft figure span{width: 100% !important;}
	.blogCenter{grid-gap: 16px;}
	.blogMidTile a{width: 100%;}
	.heading{text-align: center; line-height: 1; font-size: 18px; }
	.subHeading{text-align: center;}
	.blogLeft .blogLeftBody{width: 100%;}
	.blogLeft{width: 100%;overflow: hidden;max-width: inherit;}
	.blogTaile{ grid-template-columns:repeat(1, 1fr);grid-gap:16px;}
	.blogLeft,.blogCenter{border-bottom: solid 1px #666;padding-bottom: 15px;}
}